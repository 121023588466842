<template>
  <div class="accounts">
    <div v-if="accounts" class="chart">
      <apexchart type="bar" height="500" :options="options" :series="series" />
    </div>
    <b-spinner v-else class="spinner" label="Large Spinner" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      categoriesData: [],
      seriesData: [],
      options: {
        chart: {
          type: "bar",
          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
      },
      series: [
        {
          name: "series-1",
          data: [],
        },
      ],
    };
  },
  async mounted() {
    if(!this.accounts)
      await this.$store.dispatch("getAccounts");

    for (let key in this.accounts) {
      this.options.xaxis.categories.push(this.accounts[key].split(" - ")[0]);
      this.series[0].data.push(this.accounts[key].split(" - ")[1]);
    }
  },
  computed: {
    ...mapState(["accounts"]),
  },
};
</script>

<style scoped>
.accounts {
  padding: 50px;
  background-color: white;
  margin: 20px 0;
}
#chart {
  margin: 35px auto;
  background-color: white;
  padding-top: 40px;
  position: relative;
}
.filter__change {
  width: auto;
  padding-bottom: 15px;
  margin-top: 10px;
}
.filter__change-text {
  margin: 0px 12px;
}
.filter-label {
  background: var(--secondary-color);
}
.filter-label__defaul {
  background: #83b1f4;
}
.filter-export {
  position: absolute;
  top: 20px;
  left: 40px;
  z-index: 1;
}
.spinner {
  top: 50%;
}
</style>
